import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import {
  Call,
  Chat,
  EmojiEvents,
  Groups,
  Movie,
  TrendingUp,
  VxLive,
  VxModelsLogo,
} from '@campoint/odi-ui-icons';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { CurrencyAmount } from '../../../../components/shared/CurrencyAmount/CurrencyAmount';
import { useGetAutologinVxCashLazyQuery } from '../../../../generated/graphql';
import { externalRoutes } from '../../../../routes/routesConfig';
import { windowOpen } from '../../../../utils/utils';
import { useBarChartContext } from '../../provider/BarChart/BarChartContext';
import { useStatisticsPageStatistics } from '../../provider/StatisticsPageProvider';
import {
  StatisticsDetailedCategoryExtEnum,
  StatisticsDetailedCategoryExtEnumType,
  useTabsContext,
} from '../../provider/TabContext';
import { DetailedStatisticsDatePicker } from '../DetailedStatisticsDatePicker/DetailedStatisticsDatePicker';
import StackedBarChart from './StackedBarChart';

type DetailedStatisticsBarChartProps = {};

export const DetailedStatisticsBarChart: React.FC<
  DetailedStatisticsBarChartProps
> = () => {
  const { loading } = useStatisticsPageStatistics();
  const { totalTurnover } = useBarChartContext();
  const { tabIds, tabIndex, tabIdToName } = useTabsContext();
  const { t } = useTranslation(['statisticsPage']);
  const [
    fetchAutologinVXCash,
    { data: dataAutologinVXCash, loading: loadingAutologinVXCash },
  ] = useGetAutologinVxCashLazyQuery();

  const tabIdToIcon: Record<
    StatisticsDetailedCategoryExtEnumType,
    EmotionIcon
  > = {
    [StatisticsDetailedCategoryExtEnum.VideoLibrary]: Movie,
    [StatisticsDetailedCategoryExtEnum.Messenger]: Chat,
    [StatisticsDetailedCategoryExtEnum.LiveChat]: VxLive,
    [StatisticsDetailedCategoryExtEnum.Misc]: EmojiEvents,
    [StatisticsDetailedCategoryExtEnum.Feed]: VxModelsLogo,
    [StatisticsDetailedCategoryExtEnum.Telegram]: Chat,
    [StatisticsDetailedCategoryExtEnum.PhoneService]: Call,
    [StatisticsDetailedCategoryExtEnum.OverAll]: TrendingUp,
    [StatisticsDetailedCategoryExtEnum.Affiliate]: Groups,
  };

  const sectionTitel = tabIdToName(tabIds[tabIndex]);
  const currentTab = tabIds[tabIndex];
  const sectionIcon = tabIdToIcon[tabIds[tabIndex]];

  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <VStack>
            <SectionTitleRow>
              <SectionIcon as={sectionIcon} />
              <SectionTitle>{sectionTitel}</SectionTitle>
            </SectionTitleRow>
          </VStack>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted={true} />
      <SectionBody>
        <SectionCenterContainer spacing={4}>
          <VStack gap={4}>
            <DetailedStatisticsDatePicker />
            <Text
              fontWeight={'bold'}
              fontSize={'md'}
              letterSpacing={'0.03em'}
              color={'gray.500'}
              lineHeight={'24px'}
            >
              {t('text.DeinVerdienstDiesenMonat')}
            </Text>
            <Text
              py={4}
              fontWeight={'bold'}
              fontSize={'20px'}
              letterSpacing={'0.03em'}
              lineHeight={'24px'}
            >
              {loading ? '-' : <CurrencyAmount amount={totalTurnover} />}
            </Text>
            <StackedBarChart />
            {currentTab === StatisticsDetailedCategoryExtEnum.OverAll && (
              <HStack justify={'center'} p={'16px'} gap={'32px'}>
                <HStack>
                  <Box w="30px" h="30px" bg="#1F93E9" borderRadius="8px" />
                  <Text
                    lineHeight={'20px'}
                    fontSize={'18px'}
                    fontWeight={'500'}
                  >
                    {t('text.Gesamtumsatz')}
                  </Text>
                </HStack>
                <HStack>
                  <Box w="30px" h="30px" bg="#F6D173" borderRadius="8px" />
                  <Text
                    lineHeight={'20px'}
                    fontSize={'18px'}
                    fontWeight={'500'}
                  >
                    {t('text.Affiliate')}
                  </Text>
                </HStack>
              </HStack>
            )}
            {currentTab === StatisticsDetailedCategoryExtEnum.Affiliate && (
              <VStack p={'16px'} gap={6}>
                <Button
                  variant={'outline'}
                  onClick={async () => {
                    await fetchAutologinVXCash();
                    const vxCashLink =
                      dataAutologinVXCash?.app.autologinLinkVXCash;
                    windowOpen(vxCashLink ?? externalRoutes.vxcash(), '_blank');
                  }}
                  disabled={loadingAutologinVXCash}
                  isLoading={loadingAutologinVXCash}
                >
                  {t('button.ZuVXCash')}
                </Button>
                <Text
                  color={'darkSteel'}
                  lineHeight={'15px'}
                  fontSize={'sm'}
                  fontWeight={'normal'}
                >
                  {t('text.AuszahlungErfolgtUberVXCash')}
                </Text>
              </VStack>
            )}
          </VStack>
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};
